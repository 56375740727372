import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { SVG, Loader } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  user-select: none;

  a,
  button {
    display: ${({ fluid }) => (fluid ? `block` : `inline-block`)};
    border-radius: 8px;
    &:focus-visible {
      box-shadow: 0 0 0 2px var(--color-black);
    }
  }

  .b1 {
    line-height: 100% !important;
  }

  // changed all > inherit
  pointer-events: ${({ disabled, readOnly }) => (disabled || readOnly ? `none` : `inherit`)};
`;

const commonCss = (fluid) => css`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${fluid ? `100%` : `auto`};
  transition: 0.2s all;
`;

const PrimaryStyles = styled.div`
  ${({ fluid }) => commonCss(fluid)}
  padding: 0.5rem 1rem;
  background: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-mono-20)` : `var(--color-mono-70)`)};
    }
  }

  ${({ disabled, colorTheme }) => {
    if (!disabled) return null;
    if (colorTheme === `light`) {
      return css`
        color: var(--color-mono-20);
        background: var(--color-mono-40);
      `;
    }
    return css`
      color: var(--color-mono-40);
      background: var(--color-mono-60);
    `;
  }}
`;

const SecondaryStyles = styled.div`
  ${({ fluid }) => commonCss(fluid)}
  border: 1px solid currentColor;
  padding: 0.5rem 1rem;
  color: ${({ colorTheme }) => {
    if (colorTheme === `light`) return `var(--color-white)`;
    return `var(--color-black)`;
  }};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};
      border-color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
      background: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
    }
  }

  ${({ disabled, colorTheme }) => {
    if (!disabled) return null;
    if (colorTheme === `light`) {
      return css`
        color: var(--color-mono-20);
        border-color: var(--color-mono-20);
      `;
    }
    return css`
      color: var(--color-mono-40);
      border-color: var(--color-mono-40);
    `;
  }}
`;

const TextStyles = styled.div`
  ${({ fluid }) => commonCss(fluid)}
  color: ${({ colorTheme }) => {
    if (colorTheme === `light`) return `var(--color-white)`;
    return `var(--color-black)`;
  }};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      text-decoration: underline;
    }
  }

  ${({ disabled, colorTheme }) => {
    if (!disabled) return null;
    if (colorTheme === `light`) {
      return css`
        color: var(--color-mono-20);
        border-color: var(--color-mono-20);
      `;
    }
    return css`
      color: var(--color-mono-40);
      border-color: var(--color-mono-40);
    `;
  }}
`;

const FeatureStyles = styled.div`
  ${(fluid) => commonCss(fluid)}
  border: 1px solid currentColor;
  padding: 1rem 1.5rem;
  z-index: 201;
  position: relative;

  color: ${({ colorTheme }) => {
    if (colorTheme === `light`) return `var(--color-white)`;
    return `var(--color-black)`;
  }};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};
      border-color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
      background: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
    }
  }

  ${({ disabled, colorTheme }) => {
    if (!disabled) return null;
    if (colorTheme === `light`) {
      return css`
        color: var(--color-mono-20);
        border-color: var(--color-mono-20);
      `;
    }
    return css`
      color: var(--color-mono-40);
      border-color: var(--color-mono-40);
    `;
  }}
`;

const IconRight = styled.div`
  height: 1rem;
  width: 1rem;
  margin-left: 10px;
`;

const List = styled.ul`
  display: inline-block;
  border-radius: 0 0 8px 8px;
  border: 1px solid currentColor;
  border-top: none;
  border-bottom: none;
  position: absolute;
  background: var(--color-khaki-10);
  opacity: 1;
  z-index: 100;
  margin-top: -5px;
  width: 100%;
`;

const ListItem = styled.li`
  text-align: center;
  transition: 0.2s all;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid currentColor;
  color: ${({ colorTheme }) => {
    if (colorTheme === `light`) return `var(--color-white)`;
    return `var(--color-black)`;
  }};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};
      border-color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
      background: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
    }
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    variant   				"primary" | "secondary" | "text" | "feature"
 * @param  {string}    colorTheme   		"light" | "dark"
 * @param  {boolean}   fluid   					Whether button fills width of container
 * @param  {string}    buttonType   		Usually one of "button" or "submit"
 * @param  {string}    className    		Can be an identifier, Emotion CSS, or both
 * @param  {function}  onClick      		Click listener function
 * @param  {string}  	 to      					Used for internal links
 * @param  {string}  	 href      				Used for external links
 * @param  {boolean}   newTab      			Open external link in new tab
 * @param  {boolean}   disabled     		Disable button
 * @param  {node}      children     		Button text, icons if required
 * @param  {node}      iconLeft     		Icon to render on left side
 * @param  {node}      iconRight     		Icon to render on right side
 * @param  {boolean}   readOnly     		Button for display purposes only
 * @return {node}
 */

const EventDropDown = ({ variant = `feature`, colorTheme = `dark`, fluid, className, style, dropDownItems, selected, setSelected }) => {
  const [active, setActive] = useState(false);
  let DropDownStyles;
  if (variant === `primary`) {
    DropDownStyles = PrimaryStyles;
  } else if (variant === `secondary`) {
    DropDownStyles = SecondaryStyles;
  } else if (variant === `text`) {
    DropDownStyles = TextStyles;
  } else if (variant === `feature`) {
    DropDownStyles = FeatureStyles;
  }

  const onMouseEnter = () => {
    setActive(true);
  };

  const onMouseLeave = () => {
    setActive(false);
  };

  const onClick = () => {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  useEffect(() => {
    if (dropDownItems) {
      setSelected(dropDownItems[0]);
    }
  }, [dropDownItems?.length]);

  return (
    <Container fluid={fluid} style={style}>
      <div
        style={{
          width: `100%`,
          display: `inline-block`
        }}
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        <DropDownStyles colorTheme={colorTheme} variant={variant} className={className || null} onClick={() => onClick()}>
          {selected ? selected.title : <Loader inline />}
          <IconRight>
            <SVG svg="chevronDown" />
          </IconRight>
        </DropDownStyles>
        {dropDownItems && active && (
          <List>
            {dropDownItems.map((dropDownItem) => (
              <ListItem
                key={dropDownItem.id}
                onClick={() => {
                  setSelected(dropDownItem);
                  setActive(false);
                }}
              >
                {dropDownitem.name}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </Container>
  );
};

export default EventDropDown;
